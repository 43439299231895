<template>
    <div class="company-logo">
        <label for="id_image" class="logo__preview">
            <img
                v-if="data.file || preview"
                :src="preview"
                alt=""
                class="logo__img"
            >
            <span v-else class="logo__letter">{{data.company[0]}}</span>
        </label>
        <div class="logo__wrap">
            <label for="id_image" class="logo__load v-link">
                Загрузить <!--Изменить--> логотип
            </label>
            <span class="logo__meta">
                jpeg, jpg, png, svg не более 2 мб
            </span>
        </div>
        <div class="hidden">
            <input class="hidden" type="file" name="image" accept="image/*" id="id_image" ref="file" @change="handleFileUpload">
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompanyLogo",
        props: ['data', 'preview'],
        data() {
            return {
                imgPreview: this.preview,
                file: '',
                user: {},
                checkedProxy: null
            }
        },
        watch: {
            preview: {
                handler: function (val, oldVal) {
                    this.preview = val
                },
                deep: true
            }
        },
        methods: {
            handleFileUpload() {
                this.$emit('input');
                this.user.image = this.$refs.file.files[0];
                let reader = new FileReader();
                reader.addEventListener("load", function () {
                    const CropperModal = () => import(`../../modals/CropperModal`);
                    this.$modal.show(CropperModal, {
                            image: reader.result
                        }, {
                            name: 'CropperModal',
                            adaptive: true,
                            maxWidth: 740,
                            width: '100%',
                            height: 'auto',
                            clickToClose: false,
                            scrollable: true,
                            pivotY: 0,
                            styles: 'margin-top: 8px; margin-bottom: 8px;'
                        }, {
                            'onSave': data => {
                                this.user.x = data.x;
                                this.user.y = data.y;
                                this.user.width = data.width;
                                this.user.height = data.height;
                                this.isNewImage = true;
                            },
                            'setPreview': preview => {
                                this.preview = preview;
                            }
                        }
                    )
                }.bind(this), false);

                if (this.user.image) {
                    reader.readAsDataURL(this.user.image);
                }
            },
            onChange: function (e) {
                this.$emit('input');
            }
        },
    }
</script>

<style lang="scss">
    @import "#sass/v-style";
    .company-logo {
        display: flex;
        align-items: center;
        .logo {
            &__preview {
                display: flex;
                margin: 0;
                cursor: pointer;
                height: 54px;
                width: 54px;
                max-height: 54px;
                max-width: 54px;
            }
            &__wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
            }
            &__load {
                cursor: pointer;
                color: #2173D3;
                font-weight: normal;
                margin: 0;
            }
            &__img {
                height: 54px;
                width: 54px;
                border-radius: 4px;
            }
            &__letter {
                width: 100%;
                height: 100%;
                background: #5B6071;
                color: #A7AFCB;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.75rem;
                border-radius: 5px;
            }
            &__meta {
                color: #999;
                font-size: 0.875rem;
            }
        }
    }

</style>
