<template>
    <ValidationObserver
        tag="form"
        ref="observer"
        class="form_settings"
        @input.prevent="onChange"
        @submit.prevent="onSubmit"
    >
        <!--                        {% csrf_token %}-->
        <div class="form_settings__main">
            <input type='hidden' name="form_type" value="form">
            <fieldset class="form_settings__fieldset">
                <div class="row">
                    <div class="col-xl-4 col-md-6 col-12">
                        <div class="company-logo">
                            <label for="id_image" class="logo__preview">
                                <img
                                    v-if="form.image || preview"
                                    :src="preview || form.image"
                                    alt=""
                                    class="logo__img"
                                >
                                <span v-else class="logo__letter">{{form.company[0]}}</span>
                            </label>
                            <div class="logo__wrap">
                                <label for="id_image" class="logo__load v-link">
                                    {{!form.image ? 'Загрузить' : 'Изменить'}} логотип
                                </label>
                                <span class="logo__meta">
                                    jpeg, jpg, png, svg не более 2 мб
                                </span>
                            </div>
                            <div class="hidden">
                                <input
                                    class="hidden"
                                    type="file"
                                    name="image"
                                    id="id_image"
                                    ref="file"
                                    accept="image/png, image/jpeg, image/svg+xml"
                                    @change="handleFileUpload"
                                >
                                <input class="hidden" v-model="form.image">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12">
                        <ValidationProvider vid="company" rules="required" v-slot="provider">
                            <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                <div class="form-material">
                                    <input
                                        type="text"
                                        maxlength="255"
                                        id="id_company"
                                        class="form-control"
                                        v-model="form.company"
                                    >
                                    <label for="id_company">Название компании *</label>
                                    <div
                                        id="id_company-error"
                                        class="invalid-feedback animated fadeInDown"
                                        v-if="provider.errors.length > 0"
                                    >{{provider.errors[0]}}</div>
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12" v-if="is_box !== 'true' ">
                        <ValidationProvider vid="domain" rules="required" v-slot="provider">
                            <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                <div class="form-material input-group">
                                    <input
                                        type="text"
                                        maxlength="255"
                                        id="id_domain"
                                        class="form-control"
                                        v-model="form.domain"
                                        v-mask="{mask: '*{1,}.wikiworks.io', greedy: false}"
                                    >
                                    <label for="id_domain">Адрес портала *</label>
                                    <div
                                        id="id_domain-error"
                                        class="invalid-feedback animated fadeInDown"
                                        v-if="provider.errors.length > 0"
                                    >{{provider.errors[0]}}</div>
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12">
                        <div class="form-group">
                            <div class="form-material">
                                <input
                                    type="text"
                                    maxlength="255"
                                    id="id_off_url_company"
                                    class="form-control"
                                    v-model="form.off_url_company"
                                >
                                <label for="id_off_url_company">Сайт компании</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12">
                        <div class="form-group">
                            <div class="form-material">
                                <input
                                    type="email"
                                    maxlength="255"
                                    id="id_off_email_company"
                                    class="form-control"
                                    v-model="form.off_email_company"
                                >
                                <label for="id_off_email_company">E-mail компании</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-12">
                        <div class="form-group">
                            <div class="form-material">
                                <input
                                    type="text"
                                    maxlength="255"
                                    id="id_off_phone_company"
                                    class="form-control"
                                    v-model="form.off_phone_company"
                                    v-mask="{mask: '+7 (999) 999-9999', greedy: false}"
                                >
                                <label for="id_off_phone_company">Телефон компании</label>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="form_settings__fieldset">
                <legend class="form_settings__legend">
                    Настройки ленты на Главной странице(показ)
                </legend>

                <div class="form_settings__group form_settings__group--wall">
                    <div
                        v-for="(item, key) in displaySettings"
                        :key="`form-settings-wall-${key}`"
                        class="form_settings__item"
                    >
                        <span class="form_settings__label">
                            {{ item.label }}
                        </span>

                        <label
                            class="material-toggle"
                            :title='`Показать "${item.label}": ${item.value ? "Включено" : "Отключено"}`'
                            :aria-label="item.label"
                        >
                            <input
                                type="checkbox"
                                class="material-toggle__input"
                                v-model="item.value"
                            >
                            <span class="material-toggle__icon"></span>
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="form_settings__footer">
            <button type="submit" class="v-btn" :disabled="!isValid">
                Сохранить
            </button>
        </div>
    </ValidationObserver>
</template>

<script>
    import { mapState } from 'vuex';
    import session from '@/api/session';
    import validation from "assets/js/validate";
    import CompanyLogo from '@/components/settings/logo/CompanyLogo';

    export default {
        name: "Form",
        components: {
            CompanyLogo
        },
        data() {
            console.log('BOX', process.env.VUE_APP_BOX_VERSION)
            return {
                form: {
                    company: '',
                    domain: '',
                    off_email_company: '',
                    off_phone_company: '',
                    off_url_company: '',
                    image: null
                },
                preview: null,
                formCopy: {},
                isNewImage: false,
                isValid: false,
                validation,
                is_box: process.env.VUE_APP_BOX_VERSION,
                displaySettings: {}
            }
        },
        computed: {
            ...mapState('settings', ['settingsForm']),
            ...mapState('default_data', ['wallSettings'])
        },
        methods: {
            async onChange() {
                this.isValid = await this.$refs.observer.validate();
            },

            async onSubmit() {
                const isValid = await this.$refs.observer.validate();

                this.form.domain = this.form.domain.replace('.wikiworks.io', '');
                this.form.wall = {
                    'post': this.displaySettings['userPost'].value,
                    'advert': this.displaySettings['advertPost'].value,
                    'user': this.displaySettings['newUsers'].value,
                    'material': this.displaySettings['newMaterials'].value,
                }


                if (isValid) {
                    for (const [key, data] of Object.entries(this.displaySettings)) {
                        await this.$store.dispatch(
                            'default_data/changeWallSettings',
                            {
                                key: key,
                                value: data.value
                            }
                        );
                    }

                    let data = this.form;
                    let config = {};

                    if (this.isNewImage) {
                        let formData = new FormData();

                        config['headers'] = {
                            'Content-Type': 'multipart/form-data'
                        };

                        for (let [key, value] of Object.entries(data)) {
                            if (
                                Array.isArray(value) &&
                                value.length
                            ) {
                                for (let item of value) {
                                    formData.append(`${key}[]`, item);
                                }
                                continue;
                            }

                            if (!!value) {
                                if (key === 'wall') {
                                    formData.append(key, JSON.stringify(value));
                                } else {
                                    formData.append(key, value);
                                }
                            }

                            data = formData;
                        }
                    } else {
                        let imgData = data.image;
                        delete data.image;
                        this.form.image = imgData;
                    }

                    session
                        .put(`/api/v1/settings/1/`, data)
                        .then(response => {
                            if (response.data.url) {
                                window.location.href = response.data.url
                            } else {
                                this.isValid = false;
                                this.$store.dispatch('sidebar/initialize');
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Данные изменены',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400,
                                });
                            }
                        })
                        .catch((error) => {
                            this.errorHandler(error);
                        });
                }
            },

            async handleFileUpload() {
                let reader = new FileReader();
                let file = this.$refs.file.files[0];

                reader.addEventListener("load", function () {
                    const CropperModal = () => import(`../modals/CropperModal`);
                    let isValid = this.validation.file(file, ['jpg', 'jpeg', 'png', 'svg'], 2000);
                    this.$refs.file.value = null;
                    if (isValid) {
                        let isSvg = /\.(svg)$/i.test(file.name);
                        if (isSvg) {
                            this.form.image = file;
                            this.preview = URL.createObjectURL(file);
                            this.isNewImage = true;
                        } else {
                            this.$modal.show(CropperModal, {
                                    image: reader.result
                                }, {
                                    name: 'CropperModal',
                                    adaptive: true,
                                    maxWidth: 740,
                                    width: '100%',
                                    height: 'auto',
                                    clickToClose: false,
                                    scrollable: true,
                                    pivotY: 0,
                                    styles: 'margin-top: 8px; margin-bottom: 8px;'
                                }, {
                                    'onSave': data => {
                                        this.form.x = data.x;
                                        this.form.y = data.y;
                                        this.form.width = data.width;
                                        this.form.height = data.height;
                                        this.isNewImage = true;
                                        this.form.image = file;
                                    },
                                    'setPreview': preview => {
                                        this.preview = preview;
                                    }
                                }
                            )
                        }
                    }
                }.bind(this), false);

                if (file) {
                    reader.readAsDataURL(file);
                }
            },

            errorHandler(error) {
                console.log('error', error);

                if (error.response) {
                    this.$refs.observer.setErrors(error.response.data);
                }
            },
        },

        mounted() {
            this.form = { ...this.settingsForm };
            this.formCopy = { ...this.settingsForm };
            this.displaySettings = JSON.parse(JSON.stringify(this.wallSettings));
        },
    }
</script>

<style lang="scss">
    @import '#sass/v-style';
</style>
